<template>
<div class="row px-0">
    <div class="col-12 pt-2 px-0">
        <table id="basic-datatable" class="table table-striped  dt-responsive nowrap db"></table>
    </div>
</div>
</template>
<script>
/* eslint-disable */
import firebase from "firebase/app"
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import $ from 'jquery'

import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/functions";
const functions = firebase.app().functions('asia-east2')
import 'busy-load';

// import "datatables.net"
require('datatables.net-buttons-bs4')
require('datatables.net-responsive-bs4')
require('datatables.net-select-bs4')
require('datatables.net-bs4')
require('lightbox2/dist/js/lightbox.js')
require('lightbox2/dist/css/lightbox.css')
import Swal from 'sweetalert2'
export default {
    mounted() {
        let vue = this
        let columns = [
            {
                data: 'time',
                title: "Time",
                width:200,
                render: function (data, type, row) {
                    return `<span style="display:none;">${data}</span>` + dayjs(data).locale('th').format('DD MMMM YYYY<br>[Time :] HH:mm')
                }
            },
            {
                data: 'stationId',
                title: "Station ID",
                render: function (data) {
                    return `<a href="https://www.dj.in.th/s/${data}" target="_blank">${data}</a>` 
                }
            },
            {
                data: 'msg',
                title: "Message",
                width:'50%'
            },
            {
                data: 'uid',
                title: "OwnerId",
                render: function (data, type, row) {
                    if(!data)
                        return "BOT"
                    else
                        return data
                }
            },
            
        ]
        let table = $('#basic-datatable').DataTable({
            order: [[0, "desc"]],
            pageLength: 50,
            lengthChange: !1,
            responsive: true,
            language: {
                paginate: {
                    previous: "<i class='mdi mdi-chevron-left'>",
                    next: "<i class='mdi mdi-chevron-right'>"
                }
            },
            drawCallback: function () {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded")
            },
            columns: columns,
            select: 'single',
            columnDefs: [{
                "defaultContent": "",
                "targets": "_all",
                "className": "align-middle",
            }],
             buttons: [
                {
                    extend: 'selectNone',
                    text: 'Chat Delete',
                    action: function (e, dt, node, config) {
                        var l = table.rows(".selected").data()[0]
                        const {stationId , DT_RowID} = l

                        Swal.fire({
                            title: 'Are you sure?',
                            text: "Please confirm to Remove!",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes, Remove it!',

                        }).then(async (result) => {
                            if (result.isConfirmed)
                            {
                                vue.loadshow(true)
                                await firebase.database().ref(`chat/${stationId}/messages/${DT_RowID}`).remove()
                                vue.loadshow(false)
                            }
                        })
                    }
                },
            ]
        })
        table.buttons().container().appendTo("#basic-datatable_wrapper .col-md-6:eq(0)")
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                let insert = []
                firebase.database().ref('chat').orderByChild('message').limitToLast(50).once('value').then(function (data) {
                    data.forEach((result) => {
                        let key = result.key
                        let messages = result.val().messages
                        Object.keys(messages).forEach((id) => {
                            let l = messages[id]
                            l.DT_RowID = id
                            l.stationId = key
                            insert.push(l)
                         })
                    })
                    table.rows.add(insert).draw(true)
                })
            }
            else
                window.location.href = "/login"
        })
    },
}
</script>

<style>
.dataTables_wrapper .dataTables_filter {
    display: none;
}

.dataTables_paginate {
    float: right;
    text-align: right;
}

table.dataTable tbody>tr.selected {
    color: white
}
table.dataTable tbody>tr.selected a {
    color: white
}

.dt-buttons {
    margin-bottom: 10px !important;
}
</style>
